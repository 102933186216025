import * as React from 'react';

import {Link} from 'gatsby';

import Layout from '../components/Layout';

export default function NotFoundPage() {
	return (
		<Layout title="Seite nicht gefunden">
			<p>
				Die gewünschte Seite wurde leider nicht gefunden.
				{process.env.NODE_ENV === 'development' ? (
					<>
						<br />
						Try creating a page in <code>src/pages/</code>.
						<br />
					</>
				) : null}
				<br />
				<Link to="/">Zur Startseite</Link>
			</p>
		</Layout>
	);
}
